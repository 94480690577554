import React from "react"
import "./About.css"
import AboutCard from "./AboutCard"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import AboutCards from "./AboutCards"

const About = () => {
  return (
    <>
      <HeadTitle />

      <section className='about top'>
        <div className='container'>
          <AboutCard />
        
        </div>
        <div className='container'>
          <AboutCards />
        
        </div>
      </section>

     
    </>
  )
}

export default About
