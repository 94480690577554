const PopularData = [
  {
    id: 1,
    image: "Images/qt4.jpg",
    // country: "Italy",
    name: "Qualified Team",
    // price: "$300.00",
  },
  {
    id: 2,
    image: "Images/qt5.jpg",
    // country: "Mexico",
    name: "Individual Approach",
    // price: "$400.00",
  },
  {
    id: 3,
    image: "Images/qt7.jpg",
    // country: "United States",
    name: "100% Success",
    // price: "$500.00",
  },
  {
    id: 4,
    image: "Images/qt9.jpg",
    // country: "Thailand",
    name: "100% Satisfaction",
    // price: "$100.00",
  }
 
]

export default PopularData
