const Tdata = [
  {
    id: 1,
    desc: "When your own money stuck somewhere they can recover your amount easily without any headache of customers.Best law firm in India, & 100 percent perfection with their work.Thanks",
    name: "Kunal Singh",
    
  },
  {
    id: 2,
    desc: "Best lawyers in Gurgaon in property matters.Very nice keep it up and professional low firm.",
    name: "Priyanka Mahrolia",
    // profile: "/images/profile/profile2.jpg",
    // post: "Designer",
  },
  {
    id: 3,
    desc: "Prompt response and expert in their work. Full trustworthy.",
    name: "Berlin Motor Works",
    // profile: "/images/profile/profile3.jpg",
    // post: "Support-Marketing",
  },
  {
    id: 4,
    desc: "Very nice keep it up and professional low firm.",
    name: "Muskan Malik",
    // profile: "/images/profile/profile4.jpg",
    // post: "CEO",
  },
  {
    id: 5,
    desc: "Best law firm.",
    name: "Neha Khatana",
    // profile: "/images/profile/profile5.jpg",
    // post: "Consultant",
  },
 
]

export default Tdata
