import React from "react"
import "./Footer.css"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container grid'>
          <div className='box'>
            <h2>ABOUT US</h2>
            <p>The Firm has a team of professional and dedicated lawyers.The firm's partners and associates provides excellent legal advisory services  to clients in all the areas of law.</p>
            <br />
            {/* <p>Lorem ipsum dolor sit amet sectetur adipiscing elit amet consectetur scing elit amet.</p> */}
            <div className='icon'>

              <a href="https://www.instagram.com/advocate_himanikataria/"  target="_blank"><i className='fab fa-instagram'></i></a> 
              {/* <i className='fab fa-linkedin' style={{marginLeft:"5px"}}></i> */}
             
             
            </div>
          </div>

          <div className='box'>
            <h2>NAVIGATION</h2>
            <ul>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/about'>About us</Link>
              </li>
             
              <li>
                <Link to='/testimonial'>Testimonial</Link>
              </li>
              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>
            </ul>
          </div>

          <div className='box post'>
            <h2>Review</h2>
            <ul>
              <li>
                <p>Prompt response and expert in their work</p>
                <label className='fa fa-calendar-alt'></label>
                <span>06 March 2022</span>
              </li>
              <li>
                <p>Very nice keep it up and professional low firm</p>
                <label className='fa fa-calendar-alt'></label>
                <span>22 May 2022</span>
              </li>
              <li>
                <p>Best law firm</p>
                <label className='fa fa-calendar-alt'></label>
                <span>15 April 2022</span>
              </li>
            </ul>
          </div>

          <div className='box'>
            <h2>Email ID</h2>
            <p>mkkatariaandassociates04@gmail.com</p>

           
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>© 2022 All Rights Reserved.</p>
      </div>
    </>
  )
}

export default Footer
