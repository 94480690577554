const Data = [
  {
    image: "Images/justice.jpg",
  },
  {
    image: "Images/judge.jpg",
  },
  {
    image: "Images/justice1.jpg",
  },
]
export default Data
