import React from "react"
import mkatariaa from '../../images/mkatariaa.jpeg';
import "./About.css"


const AboutCard = () => {
  return (
    <>
      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          <h4>About Us</h4>
          <h1>
            <span>Himani Kataria</span>
          </h1>
          <p>Himani Kataria is the founder of MK Kataria and Associates, named after her renowned and highly esteemed father MR. Mahesh Kumar kataria. Miss Kataria is dedicated to take forward his legacy and brand. Graduated from GD Goenka School of Law, Ms Kataria holds a degree in LLB Honours , where she has done her dissertation on "Critical Analysis of changes introduced by companies law act 2013". Miss Kataria also holds a degree in LLM(Corporate Laws).</p>
          <p>She is specialist in “Property Laws” "Corporate Laws" and "International Commercial Arbitration". She also holds seven years of experience in real estate and degrees of B.A and M.A respectively.</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={mkatariaa} alt='' style={{    width: "auto", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default AboutCard
