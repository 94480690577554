import React from "react"
// import animatepic from  '../../images/animatepic.jpg'
import dharam from  '../../images/dharam.jpeg'
import geetan from  '../../images/geetan.jpeg'
import dush from  '../../images/dush.jpeg'
import pin from  '../../images/pin.jpeg'
import ruchiadv from  '../../images/ruchiadv.jpeg'
import kan from  '../../images/kan.jpeg'
import advm from  '../../images/advm.jpeg'
import priya from  '../../images/priya.jpeg'

import "./About.css"

const AboutCards = () => {
  return (
    <>
      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Ruchi Bhardwaj</span>
          </h1>
          <p>Ruchi Bhardwaj is a fine lawyer. Her theme of practice is Family matters, Corporate Matters and International Commercial Arbitration.

Her relentless dedication and commitment to the field of law helped various clients in their legal issues and live a better life.

She has completed her LLB(Hons) from G.D. Goenka University,Sohna. LLM (International Commercial Arbitration) from Manav Rachna University, Faridabad and got her B.com (Hons) Industry Integrated from Manav Rachna International University, Faridabad. Along with this she also carries certification in KPMG Accounting Course (duration:3 years) and Diploma in Direct and Indirect Taxes from Kaizen Edu Foundation with A+ grade.
</p>
            <p>Her other accomplishments are “Gold Medal” in LLB from G.D Goenka University and another “Gold Medal” from Manav Rachna International University for excellent academic performances.She has immensely contributed in the field of law by publishing various papers in law journals such as Refugee Laws in India, Customary International Law.</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={ruchiadv} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Dharambir Kataria</span>
          </h1>
          <p>Dharambir Kataria is a distinguished legal expert specializing in property cases. His extensive experience, spanning 40 years, and deep understanding of property law have established him as a trusted authority in the field. Over the years, Dharambir has successfully handled a myriad of complex property disputes, providing his clients with unparalleled legal counsel and representation.</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={dharam} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Geetanjali</span>
          </h1>
          <p>Result driven Lawyer with 2years experience ensuring legality of commercial transactions adept at drafting and reviewing contracts and client negotiations possess specialization in criminal and corporate law and masters in constitutional law. Is an advocate of Punjab and Haryana Bar Council.</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={geetan} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Dushyant Mudgil</span>
          </h1>
          <p>Dushyant Mudgil is a Practicing Advocate in the Law Firm and has a work experience of more than 15 years in Corporate Sector. He is practicing majorly the criminal cases in the Supreme Court and Delhi High Court. He is truly an asset to a firm who passionately and dedicatedly takes the cases in his hands with an only objective to get relief to his client. He is someone who responsibly defends the clients in the Court in the utmost professional manner. His excellence is in making the defence for his clients in all type of criminal cases. He has represented many renowned clients in the Supreme Court and various High Courts</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={dush} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Pinki Bhardwaj</span>
          </h1>
          <p>Pinki Bhardwaj has over five years of extensive experience in taxation consultancy. Her expertise in the field has equipped her with in-depth knowledge and practical skills necessary to navigate complex tax regulations and provide valuable advice to clients.</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={pin} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Kanupriya</span>
          </h1>
          <p>Kanupriya , have completed her LLB Hons. course at GD Goenka University, Gurugram She have a strong passion for Corporate Law, Business law and in Criminal law. , she have knowledge and experience in various practicing areas of law. She have a fair knowledge of research work and drafting legal documents.</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={kan} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Pooja Malik (Advocate)</span>
          </h1>
          <p>Holds a Degree of Law from GD Goenka School of Law, preceded by BA Hons. from University of Delhi. Has a good knowledge and understanding of the topics of the law.LLM from GD Goenka School of Law.She is very passionate lawyer about criminal laws</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={advm} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          {/* <h4>About Us</h4> */}
          <h1>
            <span>Priya Kataria</span>
          </h1>
          <p>Priya Kataria is a family law lawyer, also known as a family lawyer or domestic relations attorney, specializes in legal matters related to family relationships and domestic issues. She  help clients navigate complex legal issues such as divorce, child custody, child support, alimony, property division, and prenuptial agreements. Family law lawyers also assist with matters involving domestic violence, adoption, and paternity. She  provide legal guidance and representation in court proceedings, negotiations, and mediation to help clients resolve their family disputes in a fair and reasonable manner.</p>
          {/* <button className='secondary-btn'>
            Explore More <i className='fas fa-long-arrow-alt-right'></i>
          </button> */}
        </div>
        <div className='row image'>
          <img src={priya} alt='' style={{    width: "70%", height: "600px"}} />
          {/* <div className='control-btn'>
            <button className='prev'>
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default AboutCards
