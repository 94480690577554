const data = [
  // {
  //   cover: "./images/gallery/gallery-1.png",
  // },
  // {
  //   cover: "./images/gallery/gallery-2.png",
  // },
  // {
  //   cover: "./images/gallery/gallery-3.png",
  // },
  // {
  //   cover: "./images/gallery/gallery-4.png",
  // },
  // {
  //   cover: "./images/gallery/gallery-5.png",
  // },
  // {
  //   cover: "./images/gallery/gallery-3.png",
  // },
  // {
  //   cover: "./images/gallery/gallery-1.png",
  // },
]

export default data
